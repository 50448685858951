import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import Seo from '../components/seo';
import useKeenSlider from '../hooks/useKeenSlider';
import { ReactComponent as IconBuchung } from '../assets/svg/Buchung.svg';
import { ReactComponent as IconMietbedingungen } from '../assets/svg/Mietbedingungen.svg';
import { ReactComponent as IconKaution } from '../assets/svg/Kaution.svg';
import { ReactComponent as IconVollkasko } from '../assets/svg/Vollkasko.svg';
import { ReactComponent as IconPreise } from '../assets/svg/Preise.svg';
import { ReactComponent as IconFuhrpark } from '../assets/svg/Fuhrpark.svg';

function IndexPage({ data }) {
  return (
    <Layout bg="bg-black">
      <Seo
        title="Sportwagen mieten in Tirol"
        description="Ob BMW M4 oder Porsche 911: Mieten Sie jetzt Ihr Traumauto im Tiroler Unterland. ✓ Faire Preise ✓ Einfache Buchung ✓ Vollkasko inklusive"
      />

      {useKeenSlider()}


      <div className="bg-menu w-full h-full">
        <div className="boxed">
          <div className="py-16 lg:py-36">
            <div className="text-center" data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500">
              <p className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-primary-font sm:text-5xl font-arboria">GET YOUR DRIVE</p>
              <p className="max-w-2xl mt-4 text-xl text-gray-500 lg:mx-auto">Mieten Sie jetzt Ihr Traumauto bei PEAK MOTORS</p>
            </div>

            <div className="mt-10" data-sal="slide-up">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-16 md:gap-y-10">
                <div className="relative" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500">
                  <dt>
                    <p className="mt-2 text-lg font-medium leading-6 text-primary-font font-arboria">Online buchen</p>
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Einfach und unkompliziert. In unserem Online-Buchungssystem sehen Sie in Echtzeit, welche Sportwagen wann verfügbar sind. Wählen Sie Ihre Wunschdaten und
                    schließen Sie die Buchung direkt online ab, mit einer großen Auswahl an Zahlungsmethoden.
                  </dd>
                </div>

                <div className="relative" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500">
                  <dt>
                    <p className="mt-2 text-lg font-medium leading-6 text-primary-font font-arboria">Persönliches Briefing</p>
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Um das Beste aus Ihrem Sportwagen herauszuholen, machen wir Sie in Ruhe mit all seinen Funktionen vertraut. Und sollte doch einmal etwas unrund laufen: Unsere
                    Autos haben einen Vollkaskoschutz. Er ist bereits im Mietpreis inkludiert.
                  </dd>
                </div>

                <div className="relative" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500">
                  <dt>
                    <p className="mt-2 text-lg font-medium leading-6 text-primary-font font-arboria">Abenteuer starten</p>
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    Ob der Anlass ein Jubiläum ist, ein Geburtstag oder einfach das lang ersehnte Wochenende, ob Sie einem Geschäftspartner beschenken wollen, Ihren Liebsten oder
                    sich selbst – uns bleibt nur noch eins zu sagen: Hier der Schlüssel, bon voyage!
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-primary-bg w-full h-full">
        <div className="boxed">
          <div className="py-16 lg:py-36">
            <div className="text-center" data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500">
              <p className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-primary-font sm:text-5xl font-arboria">Warum PEAK MOTORS?</p>
              <p className="max-w-2xl mt-4 text-xl text-gray-500 lg:mx-auto">Sechs gute Gründe</p>
            </div>

            <div className="mt-10">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-16 md:gap-y-10 text-center">
                <div className="relative" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="500">
                  <dd className="my-2 text-center lg:absolute w-full">
                    <IconBuchung className="w-14 h-14 m-auto" />
                  </dd>
                  <dt>
                    <p className="lg:mt-20 text-lg font-medium leading-6 text-primary-font font-arboria">Unkomplizierte und schnelle Buchung</p>
                  </dt>
                </div>

                <div className="relative" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="500">
                  <dd className="my-2 text-center lg:absolute w-full">
                    <IconFuhrpark className="w-20 h-20 m-auto" />
                  </dd>
                  <dt>
                    <p className="lg:mt-20 text-lg font-medium leading-6 text-primary-font font-arboria">Premium-Fuhrpark</p>
                  </dt>
                </div>

                <div className="relative" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="500">
                  <dd className="my-2 text-center lg:absolute w-full">
                    <IconPreise className="w-14 h-14 m-auto" />
                  </dd>
                  <dt>
                    <p className="lg:mt-20 text-lg font-medium leading-6 text-primary-font font-arboria">Faire Preise</p>
                  </dt>
                </div>

                <div className="relative" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="500">
                  <dd className="my-2 text-center lg:absolute w-full">
                    <IconMietbedingungen className="w-12 h-12 m-auto" />
                  </dd>
                  <dt>
                    <p className="lg:mt-20 text-lg font-medium leading-6 text-primary-font font-arboria">Transparente Mietbedingungen</p>
                  </dt>
                </div>

                <div className="relative" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="500">
                  <dd className="my-2 text-center lg:absolute w-full">
                    <IconVollkasko className="w-12 h-12 m-auto" />
                  </dd>
                  <dt>
                    <p className="lg:mt-20 text-lg font-medium leading-6 text-primary-font font-arboria">Vollkaskoversicherung inklusive</p>
                  </dt>
                </div>

                <div className="relative" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="500">
                  <dd className="my-2 text-center lg:absolute w-full">
                    <IconKaution className="w-14 h-14 m-auto" />
                  </dd>
                  <dt>
                    <p className="lg:mt-20 text-lg font-medium leading-6 text-primary-font font-arboria">Keine Kaution</p>
                  </dt>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div className="h-full w-full bg-menu">
        <div
          className="boxed grid grid-cols-6 text-center lg:text-left py-16 lg:py-36 lg:pb-40"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          <div className="col-span-6 lg:col-span-1 text-primary-font lg:pr-12 lg:border-r-2 border-gray-500 font-arboria">
            <h3>Ausgewählte Zitate</h3>
          </div>
          <div className="col-span-6 lg:col-span-5 text-primary-font lg:pl-12" data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500">
            <h2 className="lg:leading-relaxed leading-normal py-12 lg:py-0">
              „Ein Auto ist erst dann schnell genug, wenn man morgens davor steht und Angst hat, es aufzuschließen.“
            </h2>
            <div className="mt-10" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease" data-sal-duration="500">
              <StaticImage src="../assets/images/walter02.jpg" width={120} alt="" className="rounded-full w-16 h-16 inline-block" />
              <div className="inline-block align-top ml-6 text-left">
                <h3 className="block font-arboria">Walter Röhrl</h3>
                <h4 className="block">Zweifacher Rallye-Weltmeister</h4>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="bg-primary-bg w-full h-full">
        <div className="boxed">
          <div className="py-16 lg:py-36">

            <div className="text-center" data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500">
              <p className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-primary-font sm:text-5xl font-arboria">PEAK MOTORS auf Instagram</p>
              <p className="max-w-2xl mt-4 text-xl text-gray-500 lg:mx-auto">Folge uns und bleibe auf dem neusten Stand</p>
            </div>

            <a class="block mt-12 w-fit md:inset-0 md:m-auto sal-animate" data-sal="slide-left" data-sal-easing="ease" data-sal-delay="600" data-sal-duration="500" href="https://www.instagram.com/peak.motors/" target="_blank" rel="noreferrer">
                <button class="block px-6 py-2 mt-12 text-white border border-transparent rounded bg-secondary-font hover:bg-transparent hover:border-white">Auf Instagram entdecken <svg x="0" y="0" viewBox="0 0 960 560" class="w-5 fill-current text-white inline -rotate-90"><path d="M480 344.181L268.869 131.889c-15.756-15.859-41.3-15.859-57.054 0-15.754 15.857-15.754 41.57 0 57.431l237.632 238.937c8.395 8.451 19.562 12.254 30.553 11.698 10.993.556 22.159-3.247 30.555-11.698L748.186 189.32c15.756-15.86 15.756-41.571 0-57.431s-41.299-15.859-57.051 0L480 344.181z"></path></svg>
                </button>
            </a>

          </div>
        </div>
      </div>

    </Layout>
  );
}

export default IndexPage;
