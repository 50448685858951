import React, { useRef } from 'react';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const sliderImages = graphql`
  fragment sliderImages on File {
    childImageSharp {
      gatsbyImageData(width: 420, formats: [AUTO, WEBP, AVIF], placeholder: BLURRED)
    }
  }
`;

const UseSlider = () => {
  const data = useStaticQuery(graphql`
    {
      allSliderDeJson {
        nodes {
          headline
          data
          sub
          video
          id
          sliderImages: image {
            ...sliderImages
          }
          timerSeconds
          link
          thumbBrand
          thumbModel
        }
      }
    }
  `);

  var autoPlayTimer;
  const videoPlayer = useRef(null);

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    slideChanged(s) {
      var timer;
      setCurrentSlide(s.details().relativeSlide);
      timer = data.allSliderDeJson.nodes[s.details().relativeSlide].timerSeconds * 1000;
      document.getElementsByClassName('video-player')[s.details().relativeSlide].currentTime = 0;
      clearTimeout(autoPlayTimer);
      autoPlayTimer = setTimeout(() => s.next(), timer);
    },
  });

  // function SliderThumb(props) {
  //   const number = props.number
  //   const active = currentSlide === number ? " active" : ""
  //   return (
  //     <div className={'thumb-tile' + active} onClick={(e) => e.stopPropagation() || slider.moveToSlide(number)}>
  //      <div className='thumbtext'>
  //        <p>{data.allSliderDeJson.nodes[number].headline}</p>
  //        <h2>{data.allSliderDeJson.nodes[number].sub}</h2>
  //      </div>
  //       <GatsbyImage
  //       image={getImage(data.allSliderDeJson.nodes[number].sliderImages)}
  //       alt={data.allSliderDeJson.nodes[number].headline}
  //       className="object-cover thumb-tile-img"
  //       />
  // </div>
  //   )
  // }

  return (
    <>
      <div className="relative navigation-wrapper">
        <div id="slider-component" ref={sliderRef} className="relative min-h-screen keen-slider">
          {data.allSliderDeJson.nodes.map((content, idx) => {
            const image = getImage(content.sliderImages);
            return (
              <div
                key={idx}
                className="w-screen keen-slider__slide"
                // className="flex-shrink-0 w-screen keen-slider__slide"
                data-active={currentSlide === idx}
                aria-hidden={currentSlide === idx ? "false" : "true"}
              >
                <div className="h-[100vh]">
                  <video
                    className="z-20 object-cover h-full video-player opacity-80"
                    height="100%"
                    width="100%"
                    loop
                    muted
                    autoPlay
                    ref={videoPlayer}
                    videoSrcURL={content.video}
                    playsInline
                  >
                    <source src={content.video} type="video/mp4" />
                  </video>

                  <GatsbyImage image={image} alt="" className="absolute[!important] inset-0 w-full h-full z-0" />

                  <div className="absolute inset-0 flex flex-wrap items-center justify-between w-full boxed md:max-w-screen-2xl">
                    <Link to={content.link} tabIndex={currentSlide === idx ? "0" : "-1"}>
                      <div className="absolute bottom-0 left-0 z-40 w-full px-4 py-16 mx-auto text-left cursor-pointer md:w-1/2 text-primary-font hero-component__text sm:py-20 sm:px-6 lg:px-8">
                        <h2 className="font-light delay-1000">{content.sub}</h2>
                        <h1 className="text-6xl uppercase delay-1000 lg:text-8xl font-arboria">{content.headline}</h1>
                        <p className="mt-1 text-xs font-light leading-6 sm:text-lg delay-1600">
                          {content.data} <span className="px-2 py-1 ml-2 text-xs border border-white rounded-lg lg:text-sm xl:hidden whitespace-nowrap">Online buchen</span>
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* {slider && (
                <>
                <ArrowLeft
                    onClick={() => slider.prev()}
                />
                <ArrowRight
                    onClick={() => slider.next()}
                />
                </>
            )} */}
      </div>
      <div className="hidden w-full h-full bg-black sm:block">
        <div className="relative grid w-full grid-cols-1 mx-auto text-center slider-thumbs max-w-screen-2xl sm:grid-cols-2 md:grid-cols-4 text-primary-font">
          {data.allSliderDeJson.nodes.map((content, idx) => {
            const number = idx;
            const active = currentSlide === number ? ' active' : '';
            return (
              <button key={idx} className={'thumb-tile' + active} onClick={e => e.stopPropagation() || slider.moveToSlide(number)}>
                <div className="thumbtext">
                  <p>{data.allSliderDeJson.nodes[number].thumbBrand}</p>
                  <h2>{data.allSliderDeJson.nodes[number].thumbModel}</h2>
                </div>
                <GatsbyImage
                  image={getImage(data.allSliderDeJson.nodes[number].sliderImages)}
                  alt={data.allSliderDeJson.nodes[number].headline}
                  className="object-cover h-full thumb-tile-img"
                />
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default UseSlider;

// function ArrowLeft(props) {
//   const disabeld = props.disabled ? " arrow--disabled" : ""
//   return (
//     <svg
//       onClick={props.onClick}
//       className={"arrow arrow--left" + disabeld}
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 24 24"
//     >
//       <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
//     </svg>
//   )
// }

// function ArrowRight(props) {
//   const disabeld = props.disabled ? " arrow--disabled" : ""
//   return (
//     <svg
//       onClick={props.onClick}
//       className={"arrow arrow--right" + disabeld}
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 24 24"
//     >
//       <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
//     </svg>
//   )
//   }
